<template>
  <div>
    <v-card elevation="2" outlined shaped tile>
      <v-card-title>{{ $t("navbar.calendar_holidays") }}</v-card-title>

      <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="600px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selected.companyId"
                      :label="$t('Company')"
                      :items="companies"
                      item-text="name"
                      item-value="id"
                      filled
                      required
                      :rules="requiredRules"
                      @change="onChangeData"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="date_menu"
                      v-model="select_date_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.date"
                          v-bind:label="$t('Date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="
                            selected.isDuplication
                              ? dateDuplicatedRules
                              : requiredRules
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.date"
                        no-title
                        @input="select_date_menu = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        @change="onChangeData"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.holidayType"
                      :label="$t('Type')"
                      :items="holidayTypesSelect"
                      item-text="label"
                      item-value="value"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="selected.description"
                      v-bind:label="$t('brands.description')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="isSaving"
                @click="onSaveClick(selected)"
              >
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h6">{{
            $t("confirm_delete")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">{{
              $t("cancel")
            }}</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" dark class="mb-2" @click="onItemEdit">
              {{ $t("newitem") }}
            </v-btn>
            <v-btn
              color="warning"
              dark
              class="mb-2 ml-2"
              @click="downloadExcel"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="data_table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="datas"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaldatas"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import moment from "moment";

export default {
  name: "Holidaystaticdays",
  data: function() {
    return {
      totaldatas: 0,
      datas: [],
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterable_col_list: ["companyId", "date", "holidayType", "description"],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      filterCriteria: {
        companyId: {},
        date: {},
        holidayType: {}
      },
      showEmpty: false,

      loading: true,
      options: {},
      editedIndex: -1,
      selected: null,
      valid: true,
      requiredRules: [v => !!v || "Required"],
      dateDuplicatedRules: [
        v => !!v || "Required",
        v => "la fecha seleccionada se duplica."
      ],

      showEdit: false,
      dialogDelete: false,
      deleteItemId: -1,
      holidayTypesSelect: [],
      holidayTypes: [],
      companies: [],

      select_date_menu: false,
      isSaving: false,

      /********** others **********/
      isSelecting: false,
      selectedFile: null,
      defaultButtonText: "Import Excel"
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    columns() {
      return [
        {
          key: "companyId",
          title: this.$t("Company"),
          field: "companyId",
          align: "left",
          width: 200,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.company && row.company.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              let values = Object.values(this.holidayTypes);
              return (
                <div class="custom-filter">
                  <filterableCheckboxesOthers
                    v-model={this.filterCriteria["companyId"]}
                    dataList={this.companies}
                    filterCriteria={this.filterCriteria["companyId"]}
                    itemvalue="id"
                    itemlabel="name"
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "companyId")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "date",
          title: this.$t("Date"),
          field: "date",
          align: "left",
          width: 150,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["date"]["from"]}
                    value={this.filterCriteria["date"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["date"]["to"]}
                    value={this.filterCriteria["date"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "date")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "holidayType",
          title: this.$t("Type"),
          field: "holidayType",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.type_label}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableCheckboxesOthers
                    v-model={this.filterCriteria["holidayType"]}
                    dataList={this.holidayTypesSelect}
                    filterCriteria={this.filterCriteria["holidayType"]}
                    itemvalue="value"
                    itemlabel="label"
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "holidayType")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "description",
          title: this.$t("brands.description"),
          field: "description",
          align: "left",
          width: 200,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["description"]}
                    on-input={value =>
                      (this.filterCriteria["description"] = value)
                    }
                    placeholder="Search description"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "description")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "q",
          title: this.$t("salesforce.Edit"),
          field: "actions",
          align: "center",
          width: 100,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  medium
                  color="red"
                  on-click={() => this.onItemRemove(row.id)}
                >
                  mdi-delete
                </v-icon>
              </div>
            );
          }
        }
      ];
    }
  },
  methods: {
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (cancelFilterKey === "description") {
        this.filterCriteria[cancelFilterKey] = null;
      } else this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    showloadingspinner() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#data_table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    closeloadingspinner() {
      this.loadingInstance.close();
    },
    close() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deleteItemId = -1;
      });
    },
    getQueryParams(options) {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (key === "start_date" || key === "end_date") {
            let convert_value = {
              from: "",
              to: ""
            };
            if (
              value["from"] !== "" &&
              value["from"] !== null &&
              value["from"] !== undefined
            ) {
              convert_value["from"] = moment(value["from"], "YYYY-MM-DD")
                .utc()
                .format("YYYY-MM-DD HH:mm:ssZ");
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
            if (
              value["to"] !== "" &&
              value["to"] !== null &&
              value["to"] !== undefined
            ) {
              convert_value["to"] = moment(value["to"], "YYYY-MM-DD")
                .utc()
                .format("YYYY-MM-DD HH:mm:ssZ");
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
          } else if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(".filterable-column");
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.loading = true;
      let query_params_string = this.getQueryParams(this.options);
      let fetch_url = "holiday/staticdays";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const resp = await ApiService.get(fetch_url);
      this.datas = resp.data;
      this.totaldatas = resp.total;

      if (this.datas.length > 0) {
        this.showEmpty = false;
      } else {
        this.showEmpty = true;
      }

      this.holidayTypes = resp.holidayTypes;
      this.holidayTypesSelect = resp.holidayTypesSelect;
      this.companies = resp.companies;

      this.loading = false;
    },
    async onChangeData() {
      const { companyId, date } = this.selected;
      if (companyId && date) {
        let fetch_url = `holiday/staticdays/checkduplication?companyId=${companyId}&date=${date}`;
        const response = await ApiService.get(fetch_url);
        this.selected.isDuplication = response.isduplicated;
      }
    },
    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    createSelectedModel(item) {
      let model = {
        id: _.get(item, "id"),
        companyId: _.get(item, "companyId"),
        date: _.get(item, "date"),
        holidayType: _.get(item, "holidayType"),
        description: _.get(item, "description"),
        isDuplication: false
      };
      return model;
    },
    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        this.isSaving = true;
        const body = _.omit(item);
        let save_res = null;
        if (body.id) {
          save_res = await ApiService.put(
            `holiday/staticdays/${body.id}`,
            body
          );
        } else {
          save_res = await ApiService.post(`holiday/staticdays`, body);
        }
        if (save_res.dataId) {
          logInfo("Éxito");
          this.showEdit = false;
          this.getDataFromApi();
        }
        this.isSaving = false;
      }
    },

    onItemRemove(item_id) {
      this.deleteItemId = item_id;
      console.log(this.deleteItemId);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      if (this.deleteItemId > 0) {
        this.closeDelete();
        ApiService.delete(`holiday/staticdays/${this.deleteItemId}`).then(
          () => {
            logInfo("eliminada");
            this.getDataFromApi();
          }
        );
      }
    },

    async downloadExcel() {
      this.showloadingspinner();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "holiday/staticdays/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const response = await ApiService.get(fetch_url, {
          responseType: "blob"
        });
        download(response, "datas.xlsx");
        this.closeloadingspinner();
      } catch (error) {
        this.closeloadingspinner();
      }
    },

    init() {
      this.getDataFromApi();
    }
  },

  async mounted() {
    this.init();
  }
};
</script>

<style>
.v-btn {
  text-transform: capitalize;
}
table tr td .v-btn {
  margin-bottom: 0 !important;
}
.filter_row td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
</style>
